:root {
    --price-with-discount-color: #45BAC1 !important;
}

.ProductCard {
    box-shadow: none !important;
    border: 1px solid #ddd;
    margin: 0 !important;
    height: auto !important;

    &-Name {
        overflow: visible !important;
        text-overflow: unset !important;
        white-space: normal !important;

        .ProductActions {
            &-Title {
                text-align: center !important;
                font-weight: 400;
            }
        }
    }

    .ProductPrice {
        &-MinMax {
            color: var(--price-with-discount-color) !important;
            padding: 0 .5rem;
            display: none;
        }
    }
}

.SaasSliderWidget-Slide {
    .ProductCard {
        height: 100% !important;
    }
}

@include mobile {
    .SaasSliderWidget {
        padding: 0 !important;
    }
}
