.HeaderMenu {
    &-MenuWrapper {
        --header-menu-main-item-margin-after-tablet: 0 3% 0 0;
    }
}

.SearchField {
    --search-bar-max-width-desktop: 220px;
}

.SiteUsp {
    &-List {
        --site-usp-icon-color: var(--secondary-base-color);
    }
}

.Footer {
    --footer-column-content-color: var(--secondary-light-color);
    --footer-column-title-color: white;
    --footer-column-title-font-size: 2rem;
    --footer-column-title-font-weight: 700;
    --footer-column-title-text-transform: uppercase;
    --footer-content-background-color: var(--primary-base-color);
    background: darken(#003e89, 10%);
    
    @include mobile {
        --footer-content-background-color: #45BAC1;
        --footer-column-content-background-color: #B1D3D5;
        --footer-column-title-font-size: 1.6rem;
        --footer-column-title-text-transform: uppercase;
        --footer-column-newsletter-background-color: var(--secondary-base-color);
    }
    
}

.FooterMenu {
    &-List {
        --footer-menu-link-color: var(--footer-column-content-color);
    }
}

.StoreSocial {
    &-List {
        --store-social-list-icon-color: white;
    }
}

@include mobile {
    .block-img-map {
        img {
            width: 100% !important;
            height: auto;
        }
    }
}