.SearchField { 
    width: 100% !important;

    &-Input {
        background-color: #F9F9F9 !important;
    }
}

@include desktop {
    .SearchField {
        max-width: 38.3rem !important;
        margin-right: auto !important;
        margin-left: 0 !important;
    }
}

@include narrow-desktop {
    .SearchField {
        max-width: 28.3rem !important;
    }
}
@include tablet {
    .SearchField {
        max-width: 25.3rem !important;
    }
}