.Footer{
    --footer-column-title-font-size: 1.8rem;
    --footer-content-font-size: 1.6rem;

    .FooterMenu {
        &-ListItem {
            p,
            a {
                @include desktop {
                    color: #FFF !important; 
                }
            }
        }
    }
    
    &-Content {
        @include mobile {
            padding: 0 !important;
        }
    }

    &-Column {
        padding-right: 0 !important;

        @include desktop {
            margin-right: 5rem !important;
        }

        &_isNewsletter {
            .Button {
                height:auto;
            }
        }

        @include mobile {
            &_isNewsletter {
                background-color: #E41B68 !important;

                .Footer-ColumnContent {
                    background-color: #E41B68 !important;
                }
            }
        }
    }

    @include mobile {
        &-ColumnContent {
            .FooterMenu-Link {
                color:#000 !important;
            }

            .StoreSocial-ListIcon > * {
                color:#000 !important;
            }
        }
    }
}