.ProductActions {
  &-MobileTitle {
    div.ProductBrandLogo {
      display: none;
    }
  }

  &-Usps {
    .ProductUsp {
      &-Item {
        margin-bottom: 1rem;
      }

      &-Icon {
        min-width: 14px !important;
        width: 14px;
        margin: 0 1rem;
      }
    }
  }

  .ProductStock {
    &-Icon {
      margin: 0 1rem;

      &_type_in_backorder {
        color: #fbc214;
      }
    }
  }
}
