
:root {
    @include mobile {
        --saas-header-wrapper-height: 11rem !important;
    }
}

.SaasHeader {
    &-NavMenu {
        padding: 0 !important;
    }

    &-NavLinks {
        justify-content: center;
        right: 5px !important;
    }

    &-NavLogo {
        margin: 0 auto 0 3rem !important;
    }
}

@include mobile {
    .SaasHeader {

        &-LogoWrapper {
            max-width: 22rem !important;
        }

        &-Nav {
            padding: 0.5rem 0 !important;
            min-height: auto !important;
        }

    }

}
