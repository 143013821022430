.SaasHeader {
    &-NavMenu {
        .MenuLink {
            &-Link {
                font-size: 1.4rem !important; 
                padding: 0 1.5rem;         
            }
            &-Title {
                font-size: 1.4rem;
            }
        }
    }
}

@include desktop {
    .SaasHeader {
        &-NavMenu {
            .MenuLink {
                &-Link {
                    color: #FFF;
                    text-transform: uppercase;

                    &:hover {
                        color: #FFF;
                        background-color: #2F8388;
                    }
                }
            }

            .MenuRegular {
                &-MainItem {
                    &:last-of-type {
                        margin-left: auto;
                        background-color: #2F8388;
                    }
                }
            }

            .MenuContent {
                &-Container {
                    .MenuLink {
                        &-Link {
                            color: #000;
                            text-transform: none;
                            padding: 0;
                            
                            &:hover {
                                color: #333;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        } 
    }
}

@include before-wide-desktop {
    .SaasHeader {
        &-NavMenu {
            .MenuLink {
                &-Link {
                    font-size: 1.3rem !important; 
                    padding: 0 1rem;         
                }
            }
        }
    }
}

@include narrow-desktop {
    .SaasHeader {
        &-NavMenu {
            .MenuLink {
                &-Link {
                    font-size: 1.2rem !important; 
                    padding: 0 1rem;         
                }
            }
        }
    }
}

@include tablet {
    .SaasHeader {
        &-NavMenu {
            .MenuLink {
                &-Link {
                    font-size: 1.2rem !important; 
                    padding: 0 1rem;         
                }
            }
        }
    }
}