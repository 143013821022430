@include mobile {
    .BannerGroup {
        &-Container {
            &.main-banner {
                margin: 1.5rem auto 1.5rem auto !important;
                
                .BannerSlider-Container_type_column_1 {
                    --banner-slider-height: 25rem;
                }
            }

            &.small-banners {
                .BannerSlider-Container_type_column_1 {
                    --banner-slider-height: 21rem !important;
                }
            }
        }
    }
}