@include desktop {
    .Button {
        &:hover {
            background-color: #3DACB2 !important;
            border-color: #3DACB2 !important;
        }

        &_isHollow,
        &_likeLink {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
}