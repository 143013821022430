/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
  opacity: 0;
  animation: appear 100ms 300ms forwards;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    margin-block-end: 0;
    margin-block-start: 0;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 30px;
    margin-top: 40px;
    color: var(--primary-base-color);
    line-height: 1;
  }

  p {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }

  ul {
    text-align: left;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
      margin-left: 30px;
      list-style: disc;
    }
  }

  .button-container {
    text-align: center;
    width: calc(100% + 20px);
    margin-left: -10px;

    a {
      margin: 10px;
    }
  }

  &-Button {
    margin-block-start: 24px;

    @include mobile {
      margin-block-start: 28px;
    }
  }

  &-Subtitle {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    line-height: 48px;

    @include mobile {
      font-size: 42px;
      line-height: 64px;

    }
  }

  &-Wrapper {
    text-align: center;
  }
}
