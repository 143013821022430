@import 'variables';

@include mobile {
    .CmsPage {
        margin-top: 0 !important;
    }
}

.Logo-Placeholder img {
    opacity: 0 !important;
    min-height: 70px;
}

a.Button,
.SaasButtonWidget-Button {
    border-radius: 0.25rem !important;
}

.SaasButtonWidget {
    &-Wrapper {

        @include desktop {
            margin-bottom: 4rem !important;
        }

        @include mobile {
            margin-bottom: 2rem !important;
            padding: 0 !important;
        }
    }

    &-Button {
        padding: 1rem !important;

        &:hover {
            background-color: #BA1755 !important;
        }
    }
}

.SaasSliderWidget,
.HomePage-Blocks-one,
.HomePage-Blocks-two,
.Footer-Content {

    @include desktop {
        padding: 0 2rem !important;
    }

    @include mobile {
        padding: 0 1.5rem !important;
    }
}

.HomePage {
    &-Blocks-one {
        display: flex !important;

        @include desktop {
            padding-top: 5rem !important;
        }

        @include mobile {
            flex-direction: column;
            padding-bottom: 3rem;
        }

        .LayoutGroupWidget {
            &-Item {
                &:first-of-type {

                    .ContentWrapper {
                        h1,
                        h2 {
                            text-transform: uppercase !important;
                            font-size: 2.5rem !important;

                            @include mobile {
                                text-align: left;
                                margin-bottom: 1rem;
                                font-size: 1.8rem !important;
                            }
                        }

                        .SaasButtonWidget {
                            &-Button {
                                margin-top: 2rem;
                            }
                        }
                    }
                }

                &:last-of-type {

                    @include desktop {
                        width: calc(100% - 50rem);
                    }

                    @include mobile {
                        width: 100%;
                    }

                    .CmsBlock-Wrapper {
                        display: flex;

                        @include mobile {
                            flex-direction: column;
                        }
                    }

                    .block-img {
                        &-shop {
                            border: 0.1rem solid #DDD;
                            background-color: #FFF;
                            width: 100%;
                            margin-bottom: auto;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: 50% 50%;

                            @include desktop {
                                height: 37rem;
                                margin-right: 1.5rem;
                                max-width: 60rem;
                            }

                            @include mobile {
                                height: 21rem;
                            }
                        }

                        &-map {
                            width: 100%;
                            margin-bottom: auto;
                            background-color: #EEE;
                            border: 0.1rem solid #DDD;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include desktop {
                                max-width: 35rem;
                                height: 37rem;
                                margin-left: 1.5rem;
                            }

                            @include mobile {
                                max-width: 100%;
                                height: 35rem;
                            }

                            img {
                                max-width: 25rem;
                            }
                        }
                    }

                    .ContentWrapper {
                        h1 {
                            text-transform: uppercase !important;
                        }

                        .SaasButtonWidget {
                            &-Button {
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.HomePage {
    &-Blocks-two {
        display: flex !important;

        @include desktop {
            padding-top: 5rem !important;
        }

        @include mobile {
            padding-top: 2rem !important;
            flex-direction: column;
            margin: 0 auto 1rem auto !important;
        }

        .LayoutGroupWidget {
            &-Item {
                &:first-of-type {
                    .CmsBlock-Wrapper {
                        display: flex;

                        @include mobile {
                            flex-direction: column;
                        }

                        figure {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;

                            @include desktop {
                                width: 22rem;
                            }

                            @include mobile {
                                padding-bottom: 2rem;
                            }

                            img {
                                border-radius: 50%;

                                @include desktop {
                                    max-width: 22rem;
                                    max-height: 22rem;
                                }

                                @include mobile {
                                    max-width: 18rem;
                                    height: 18rem !important;
                                }
                            }
                        }

                        article {
                            @include desktop {
                                padding-left: 3rem;
                                width: calc(100% - 22rem);
                            }

                            @include mobile {
                                text-align: left;
                            }

                            h2 {
                                @include mobile {
                                    text-align: left;
                                    margin-top: 1rem;
                                }
                            }
                        }
                    }
                }

                &:last-of-type {
                    width: 100%;
                    max-width: 48rem;

                    .CmsBlock-Wrapper {
                        border: 0.1rem solid #DDD;
                        padding: 0 2.5rem;
                        margin-top: auto;
                        display: flex;
                        flex-direction: column;
                        height: auto;

                        .head {
                            width: calc(100% + 5rem);
                            border-bottom: 0.1rem solid #DDD;
                            margin: 0;
                            margin-left: -2.5rem;
                            padding: 1rem 2.5rem;
                            margin-bottom: 2rem;
                            text-transform: uppercase;
                            font-size: 2rem;
                        }

                        p {
                            margin-bottom: 2rem !important;
                        }

                        .customer-icons {
                            padding-left: 0;
                            margin-bottom: 0 !important;

                            li {
                                list-style: none;
                                a {
                                    display:flex;
                                    color: #272323;

                                    i {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 3rem;
                                        height: 3rem;
                                        margin-right: 1rem;

                                        &:before {
                                            font-size: 2.3rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.homepage-brand-slider {
    .BannerItem {

        @include desktop {
            height: 8rem;
        }

        @include mobile {
            height: 10rem;
        }

        &_isGradient {
            &:after {
                background: none !important;
                width: 80% !important;
            }
        }
    }

    @include mobile {
        .swiper-button-next {
            right: 0;
            top: 5.3rem;
        }

        .swiper-button-prev {
            left: 0;
            top: 5.3rem;
        }
    }
}

@include desktop {
    .MyAccountOverlay {
        top: 59px !important;
    }

    .CartOverlay {
        top: 55px !important;
    }
}

.Breadcrumbs {
    background-color: #FFF !important;
    border-bottom: 0.1rem solid #DDD;

    @include mobile {
        display: block !important;
    }
}

@include mobile {
    .CategoryPage {
        margin-top: 0 !important;

        .ContentWrapper {
            margin-top: 0 !important;
        }

        .CategorySeoContent {
            padding: 2rem 1.5rem !important;
        }
    }

    .ProductPage {
        padding-bottom: 2rem !important;
        margin-top: 0 !important;

        .ProductSectionNavigation-Item {
            .ProductLinks-List {
                grid-template-columns: 2fr 1fr !important;
                display:flex;
                flex-wrap: nowrap;

                @include mobile {
                    display: grid !important;
                    grid-template-columns: repeat(2,2fr) !important;
                }
            }
        }

        .ProductSectionNavigation-ItemReviews {
            padding: 0 1.5rem;
            margin: 0 !important;
        }
    }
}

.block-img-map {
    max-width: 54rem;

    img {
        width: 100% !important;
        height: auto;
    }
}

@include mobile {

    .CategoryPage-Miscellaneous {
        top: 0 !important;
    }

}

.CompleteOrder {
    &-Content {
        h2 {
            text-transform: uppercase;

            @include desktop {
                font-size: 2rem;
            }

            @include mobile {

            }
        }
    }

    &Totals-ItemPrice_isTotal {
        color: #45BAC1 !important;
    }
}

#simple-translate {
    display: none;
}
