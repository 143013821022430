.CategorySeoContent {
  box-shadow: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;

  h2 {
    font-weight: 700;
    font-style: normal !important;

    @include desktop {
      font-size: 2.5rem !important;
    }

    @include mobile {
      font-size: 2rem !important;
    }
  }
}