:root {
    @include desktop {
        --banner-slider-height-is-full-width: 56rem !important;
    }

    @include mobile {
        --banner-slider-height-is-full-width: 36rem !important;
    }
}

.BannerItem {
    &-Title {
        text-transform: uppercase !important;
    }

    &-Content {
        font-style: normal !important;
        font-size: 1.6rem !important;
        line-height: 1.4 !important;
    }

    &-Link {
        border: none !important;

        &.Button {
            &:hover {
                background-color: #BA1755;
            }
        }
    }
}

.small-banners {
    .BannerBlock-Container {

        @include desktop {
            row-gap: 8rem !important;
        }

        @include mobile {
            row-gap: 6rem !important;
            grid-template-columns: repeat(2,1fr) !important;

            .BannerItem {
                height: 15rem !important;
            }
        }
    }

    .swiper-container {

        @include mobile {
            height: 25rem !important;
        }

        @include desktop {
            height: 31rem !important;
        }
        

        .swiper-button {
            &-next,
            &-prev {
                @include desktop {
                    top: calc(50% - 3rem);
                }

                @include mobile {
                    top: auto;
                    bottom: 50%;
                }
            }

            @include mobile {

                &-next {
                    right: 1rem;
                }

                &-prev {
                    left: 1rem;
                }
            }
        }
    }

    .BannerItem {
        &-ContentBlock {
            background-color: #fff;
            border: 0.1rem solid #ddd;
            padding: 0 !important;
            width: 100% !important;

            @include desktop {  
                margin-bottom: -6rem !important;
            }

            @include mobile {
                margin-bottom: -4rem !important;
            }
        }

        &-Title,
        &-Subtitle,
        &-Content{
            display: none !important;
        }

        &-Link  {
            &.Button {
                padding: 0 !important;
                color:#000 !important;
                background-color: transparent !important;
                height: auto !important;
                max-height: 6.2rem;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @include desktop {
                    padding: 2rem !important;
                }

                @include mobile {
                    padding: 1rem !important;
                }

                &:after {
                    content: "\f054";
                    font-size: 1.8rem !important;
                    font-family: "Font Awesome 5 Free";
                    margin-left: auto;
                }
            }
        }
    }
}

.medium-banners {
    .BannerBlock-Container{
        &_column_2 {

            @include desktop {
                height: 65rem;
            }

            @include mobile {
                height: auto;
            }
        }
    }

    .BannerItem {
        &-ContentBlock {
            background-color: #fff;
            border: 0.1rem solid #ddd;
            margin-bottom: -20rem;
            padding: 2rem !important;
        }

        &-Title {
            color: #000 !important;
        }

        &-Content {
            color: #000 !important;
        }

        &-Subtitle {
            color: #000 !important;
        }

        &-Link {
            &.Button {
                background-color: #45BAC1;
    
                &:hover {
                    background-color: #3BA4AA;
                }
            }
        }
    }
}

@include mobile {
    .medium-banners .BannerBlock-Item_type_column_2 {
        height: auto;
        display: flex;
        flex-direction: column;
        grid-template-columns: none;

        .BannerItem {
            &-Image {
                position: relative;
                height: 25rem;
            }

            &-ContentBlock {
                margin: 0;
            }
        }
    }
}

.BannerGroup-Container {
    &.ContentWrapper_isFluid {
        .BannerItem {
            &-Title {
                text-transform: uppercase;
                
                @include desktop {
                    font-size: 4rem !important;
                    margin-bottom: 2rem !important;
                }

                @include mobile {
                    font-size: 2.5rem !important;
                    margin-bottom: 1rem !important;
                }
            }

            &-Content {

                @include desktop {
                    font-size: 1.8rem !important;
                    line-height: 1.4;
                    margin-bottom: 2rem !important;
                }

                @include mobile {
                    font-size: 1.6rem !important;
                    line-height: 1.4;
                    margin-bottom: 1rem !important;
                }
            }
        }
    }
}

@include mobile {
    .SaasSliderWidget {
        .swiper {
            &-container {
                padding-bottom: 6rem;

                .swiper-button-prev,
                .swiper-button-next {
                    bottom: 2rem;
                }
            }
        }
    }
}